import axios from 'axios'
import VueHighcharts from 'vue2-highcharts'

export default {
  components: {
    VueHighcharts
  },
  data () {
    return {
      options: {
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45
          },
          height: '800px'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: ''
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45
          }
        },
        series: [
          {
            name: 'Número de Alunos',
            data: null
          }
        ]
      }
    }
  },
  mounted () {
    this.$nextTick(function () {
      const url = 'http://192.168.0.100:5011/api/reportstudent/total/gender'

      axios.get(url)
        .then(response => {
          let responseData = []
          let result = response.data.result

          result.forEach(item => {
            let itemArr = [item.name, item.count]
            responseData.push(itemArr)
          })

          let chartData = [
            {
              name: 'Número de Alunos',
              data: responseData
            }
          ]

          this.options.title.series = chartData
        })
        .catch(e => {
          console.log(`Erro ao tentar obter os dados para o gráfico "Alunos Por Idade": \n ${e}`)
        })
    })
  }
}
