import Search from '@/components/layout/Search/search.vue'

export default {
  name: 'tableReports',

  components: {
    [Search.name]: Search,
  },

  data() {
    return {
      labelSearch: 'Buscar Alunos',
      emptyText: 'Clique em um elemento do gráfico para o preenchimento desta tabela',
      rows: 100,
      perPage: 10,
      currentPage: 1,
      totalyStudents: 7000,
      fields: [
        {
          key: 'id',
          label: 'CÓDIGO',
          class: 'left-header-border text-center'
        },
        {
          key: 'name',
          label: 'NOME',
          class: 'middle-header text-center'
        },
        {
          key: 'sex',
          label: 'SEXO',
          class: 'middle-header text-center'
        },
        {
          key: 'age',
          label: 'IDADE',
          class: 'middle-header text-center'
        },
        {
          key: 'entity',
          label: 'ENTIDADE',
          class: 'middle-header text-center'
        },
        {
          key: 'modality',
          label: 'MODALIDADE',
          class: 'middle-header text-center'
        },
        {
          key: 'localTraining',
          label: 'LOCAL DE TREINAMENTO',
          class: 'middle-header text-center'
        },
        {
          key: 'healthPlan',
          label: 'PLANO DE SAÚDE',
          class: 'middle-header text-center'
        },
        {
          key: 'school',
          label: 'ESCOLA',
          class: 'middle-header text-center'
        },
        {
          key: 'rentFamily',
          label: 'RENDA FAMILIAR',
          class: 'middle-header text-center'
        },
        {
          key: 'bpfamily',
          label: 'BOLSA FAMÍLIA',
          class: 'middle-header text-center'
        },
        {
          key: 'nameMother',
          label: 'NOME - MÃE',
          class: 'middle-header text-center'
        },
        {
          key: 'nameFather',
          label: 'NOME - PAI',
          class: 'middle-header text-center'
        },
        {
          key: 'birthYear',
          label: 'DATA NASCIMENTO',
          class: 'middle-header text-center'
        },
        {
          key: 'status',
          label: 'STATUS',
          class: 'middle-header text-center'
        },
        {
          key: 'yearReenrollment',
          label: 'ANO REMATRÍCULA',
          class: 'middle-header text-center'
        },
        {
          key: 'dtLastUpdate',
          label: 'DATA ÚLTIMA ALTERAÇÃO',
          class: 'right-header-border text-center'
        }
      ]
    }
  }
}